// 全局配置
let baseUrl = '';
let isEnv = true;//环境: 正式 测试 true; 开发 false
if (process.env.NODE_ENV === 'development'){//开发
    baseUrl = "http://192.168.0.25:9999/"  // 开发
    // isEnv = false
} else if (process.env.NODE_ENV === 'production') {//生产 及上线
    baseUrl = "https://api.hzchaoxiang.cn/";   //正式
    // baseUrl = "https://api.saas.diandian11.com/server/"   ///测试
    isEnv = true
}
const imgUrl = 'https://changchong.oss-cn-hangzhou.aliyuncs.com/gzh/';
const phone = "057188583856";

const business = "api-business/api/";
const device = "api-device/api/";
const order = "api-order/api/";
                      
const newBusiness = 'api-business/';
const newDevice = 'api-device/';
const newOrder = 'api-order/';
const newMarketing = 'api-marketing/';
export { 
  baseUrl, 
  isEnv,
  imgUrl,
  phone,
  business, 
  device, 
  order,
  newBusiness,
  newDevice,
  newOrder,
  newMarketing
};
