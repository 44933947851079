import React, { Component } from 'react'
import { urlResolver } from '@/utils/commons';
import './advertising.scss';
export default class advertising extends Component {
  state = {
    urlData:''
  }
  render() {
    return (
      <div className="advertising">
        <img
          src={urlResolver(this.props.location.search).img}
          alt="暂无图片"
          className="advertisingImg" 
        />
      </div>
    )
  }
}
