import React, { Component } from 'react';
import { getPlatformUserActivity } from '@/api/api';
import { isEnv } from "@/config/index";
import { urlResolver } from '@/utils/commons';
import { checkisEnv } from '@/utils/utils'
import classnames from 'classnames';
import './payActive.scss';
import qs from 'qs'
export class payActive extends Component {
  state = {
    miniarr: [],
    largearr: [],
    Num: 0,
  }
  componentDidMount() {
    if (!isEnv) {
      let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6InljMzYyNDEwNTYiLCJ1c2VyTW9iaWxlIjoiMTgyNTgxNzU4NDAiLCJ1c2VySWQiOjE1ODY4NDR9.0kE4swArLn29P40K_xzyL5bfmtv_CjcalY1T2s579-I";
      urlResolver(`?token=${token}`)
    } else {
      let url = this.props.location.search;
      urlResolver(url);
    }
    this.getList();
  }
  // 获取我的优惠列表所有数据
  getList() {
    getPlatformUserActivity({}).then(({ success, data }) => {
      if (success) {
        if (data && Array.isArray(data)) {
          const arr1 = []
          const arr2 = []
          data.forEach((item, index) => {
            data[index].consumeMinMoney = Number(data[index].consumeMinMoney) / 100
            data[index].money = Number(data[index].money) / 100
            if (item.type === 3) {
              arr1.push(item)
            }
            if (item.type === 4) {
              arr2.push(item)
            }
          })
          this.setState({
            miniarr: arr1,
            largearr: arr2
          })
        }
      }
    })
  }
  goPay = (item) => {
    const params = {
      money: item.consumeMinMoney.toString(),
      activityId: item.couponId,
      sourceType: 'active',
    }
    checkisEnv().then(res => {
      switch (res) {
        case 'alipay':
        case 'alipay_mini':
          window.my.navigateTo({
            url: `/pages/moneyPay/index?${qs.stringify(params)}`
          })
          break
        case 'weapp_mini':
          window.wx.miniProgram.navigateTo({
            url: `/pages/moneyPay/index?${qs.stringify(params)}`
          })
          break
      }
    })
  }
  render() {
    const { Num, miniarr, largearr } = this.state
    return (
      <div className='active_container'>
        <div className='active_box'>
          <div className='active_top'> </div>
          <div className='active_center'>
            <div className='list_top'>
              <div className={classnames('title_item', Num === 0 && 'active')} onClick={() => {
                this.setState({
                  Num: 0
                })
              }
              }>
                <span className="fonts">电瓶车充值</span>
                {Num === 0 && <span className='xialine'></span>}
              </div>
              <span className='line'>|</span>
              <div className={classnames('rowline', 'title_item', Num === 1 && 'active')}
                onClick={() => {
                  this.setState({
                    Num: 1
                  })
                }
                }>
                <span className="fonts">汽车充值</span>
                {Num === 1 && <span className='xialine'></span>}
              </div>
            </div>
            <div className='list'>
              {miniarr && Num === 0 && miniarr.map((item, index) =>
                <div className='list_item' key={'id' + index}>
                  <div className='list_title'>
                    <span className='font-14'>{`充值${item.consumeMinMoney}送`}</span>
                  </div>
                  <div className='list_content'>
                    <div className='content_top'>
                      <span className='font-44'>{item.money}</span>
                      <span className='font-18'>元</span>
                    </div>
                    <div className='content_bot'>
                      <span className='font-14'>优惠券</span>
                    </div>
                  </div>
                  <div className='list_bot' onClick={
                    () => {
                      this.goPay(item)
                    }
                  }>
                    <p className='font-18'>去充值</p>
                  </div>
                </div>
              )
              }
              {largearr && Num === 1 && largearr.map((item, index) =>
                <div className='list_item' key={'id' + index}>
                  <div className='list_title'>
                    <span className='font-14'>{`充值${item.consumeMinMoney}送`}</span>
                  </div>
                  <div className='list_content'>
                    <div className='content_top'>
                      <span className='font-44'>{item.money}</span>
                      <span className='font-18'>元</span>
                    </div>
                    <div className='content_bot'>
                      <span className='font-14'>优惠券</span>
                    </div>
                  </div>
                  <div className='list_bot' onClick={
                    () => {
                      this.goPay(item)
                    }
                  }>
                    <p className='font-18'>去充值</p>
                  </div>
                </div>
              )
              }
            </div>
          </div>
          <div className='active_bottom'> </div>
        </div>
      </div>
    )
  }
}

export default payActive
