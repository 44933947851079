import React, { Component } from 'react';
import { getMyCardPackage } from '@/api/api';
import { isEnv, imgUrl } from "@/config/index";
import { urlResolver } from '@/utils/commons';
import { Modal } from '@/components';
import classnames from 'classnames';


import './myCoupon.scss';

export class myCoupon extends Component {
  state = {
    cardArr:[
      {
        title:'线上卡',
        key:'onlineCardList',
        class:'lineCard',
        type: 0,
      },
      {
        title:'积分卡',
        key:'userPointPackageList',
        class:'pointCard',
        type: 1,
      },
      {
        title:'免费充电',
        key:'memberCardList',
        class:'freeCard',
        type: 2,
      },
    ],
    CouponData: {
      memberCardList: [
        {                
          "id":1,                
          "memberSchemeName":"mock",                
          "chargeType":1,                
          "chargeTypeValue":1,                
          "dayChargeNumber":1,                
          "siteIdList":"mixed",                
          "description":"mock"                
        }
      ],
      onlineCardList: [
        {
          cardKey: "AAAAAAAA",
          createTime: "2019-10-17 14:42:46",
          id: 6915,
          isBind: 1,
          tenantId: 0,
          tenantMobile: "",
          tenantName: "未关联商家",
          upMoney: 0,
        },
        {
          cardKey: "AAAAAAAA",
          createTime: "2019-10-17 14:42:46",
          id: 6915,
          isBind: 0,
          tenantId: 0,
          tenantMobile: 18258175840,
          tenantName: "未关联商家",
          upMoney: 0,
        }
      ],
      userPointPackageList: [
        {                
          "id":1,                
          "userId":1,                
          "point":1,                
          "pointSchemeId":1,                
          "pointSchemeName":"mock",                
          "getRatioMoney":1,                
          "getRatioPoint":1,                
          "consumeRatioMoney":1,                
          "consumeRatioPoint":1,                
          "useMaxPercent":1,                
          "consumeWayType":"mock",                
          "siteIdList":"mixed"                
        }
      ],
    },//优惠数据
    currentNum: -1,
    isKnow: false,//知道了
    isHint: false,//提示框
    isHandle: false,//操作框
    explainTitle: '',
    explainArray: '',
  }
  componentDidMount() {
    if(!isEnv){
      let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6InljMzYyNDEwNTYiLCJ1c2VyTW9iaWxlIjoiMTgyNTgxNzU4NDAiLCJ1c2VySWQiOjE1ODY4NDR9.0kE4swArLn29P40K_xzyL5bfmtv_CjcalY1T2s579-I";
      urlResolver(`?token=${token}`)
    } else {
      let url = this.props.location.search;
      urlResolver(url);
    }
    // this.getList()
  }
  // 获取我的优惠列表所有数据
  getList(){
    getMyCardPackage().then(({success, data})=>{
      if(success){
        console.log('data',data)
        this.setState({
          CouponData: data
        })
      }
    })
  }
  // 线上卡
  lineCardBox =(data,index)=>{
    const { cardKey, isBind, tenantId, tenantName, upMoney } = data;
    const { cardArr } = this.state;
    return(
      <div className={classnames('list_item', cardArr[index].class)}>
        <div className={classnames('item_box')}>
          <div className={classnames('item_top')}>
            <h3 className='font14'>{tenantName}</h3>
            <button 
              className={classnames(isBind === 1?'top_btn':'font12_line')}
              onClick={()=>{
                if(isBind === 1){
                  this.gotoRecharge()
                } else {
                  this.lookOverCard(0,data)
                }
              }}
            >{isBind === 1?'充值':'领卡方式'}</button>
          </div>
          <div className={classnames('item_center','row')}>
            <span className='font10'>{`余额: `}</span>
            <span className='font28'>{Number(upMoney)/100}</span>
            <span className='font10'>{` 元`}</span>
          </div>
          <div className="item_bottom">
            <button className='font12_line' onClick={()=>{this.mapfun(tenantId)}}>查看适用设备范围</button>
            <button className='font12' onClick={()=>{
              this.setState({
                [isBind === 1?'isHint':'isHandle']:true
              })
            }}>{`${isBind === 1?'解绑':'绑定'} >`}</button>
          </div>
        </div>
        {isBind === 1 &&<span className='item_cardNum'>{`卡号:${cardKey}`}</span>}  
      </div>
    )
  }
  //积分充电
  pointCardBox =(data,index)=>{
    const { point } = data;
    const { cardArr } = this.state;
    return(
      <div className={classnames('list_item', cardArr[index].class)}>
        <div className={classnames('item_box')}>
          <div className={classnames('item_top')}>
            <h3 className='font14'>{}</h3>
          </div>
          <div className={classnames('item_center','column')}>
            <span className='font10'>{`我的积分值`}</span>
            <span className='font28'>{point}</span>
          </div>
          <div className="item_bottom">
            <button className='font12_line' onClick={()=>{this.mapfun()}}>查看适用设备范围</button>
            <button className='font12_line' onClick={()=>{this.lookOverCard(2,data)}}>积分规则</button>
          </div>
        </div>
      </div>
    )
  }
  // 免费充电
  freeCardBox =(data,index)=>{
    const { cardArr } = this.state;
    return(
      <div className={classnames('list_item', cardArr[index].class)}>
        <div className={classnames('item_box')}>
          <div className={classnames('item_top')}>
            <h3 className='font14'>{''}</h3>
            <button className='font12_line' onClick={()=>{this.lookOverCard(1,data)}}>{'使用说明'}</button>
          </div>
          <div className={classnames('item_center','row')}>
            <span className='font20'>免费充电</span>
          </div>
          <div className="item_bottom">
            <button className='font12_line' onClick={()=>{this.mapfun()}}>查看适用设备范围</button>
          </div>
        </div>
      </div>
    )
  }
  //打开 领卡弹框 使用说明
  lookOverCard =(type, data)=> {
    let arr = [];
    switch(type){
      case 0:
        arr = [
          { word:'1.到物业直接领取线上卡 ' },
          { word:'2.拨打电话', telephone: data.tenantMobile }
        ]
        break;
      case 1:
        arr = [ { word: data.description } ]
        break;
      case 2:
        arr = [ { word: data.pointSchemeName } ]
        break;
      default:
        arr =[];
    }
    let TitleArr = ['领卡方式','使用说明','规则说明']
    this.setState({ 
      isKnow: true,
      explainTitle: TitleArr[type],
      explainArray: arr,
    })
  }
  // 弹框 确定 取消 操作
  modalBtn =( key, Bool, value )=>{
    console.log('value, key, Bool',value, key, Bool)
    this.setState({
      [key]: false
    })
  }
  // 充值
  gotoRecharge =()=>{
    console.log('去充值')
  }
  // 地图查看范围
  mapfun(tenantId){
    console.log('tenantId',tenantId)
  }

  render() {
    const { cardArr, CouponData, isKnow, isHint, isHandle, explainTitle, explainArray, currentNum  } = this.state;
    return (
      <div className='myCoupon_container'>
        <div className='myCoupon_box'>
          {
            Object.keys(CouponData).length > 0 && Object.keys(CouponData).map((item,index)=>
              <div className='CouponList_box' key={item}>
                <div className='list_head'>
                  <h3 className='head_title'>{cardArr[index].title}</h3>
                  {false&&<div className='head_recommend'>
                    <h3 className='recommend_text'>{`推荐购买（${index}）`}</h3>
                    <img className='recommend_icon' src={imgUrl+'icon/right.png'} alt="icon"/>
                  </div>}
                </div>
                <ul className="myCoupon_list">
                  { CouponData[cardArr[index].key].length !== 0 &&CouponData[cardArr[index].key].map((it,i)=>
                      <li className={classnames('list_items')} key={cardArr[index].key+i}>
                        {cardArr[0].type === index&&(currentNum === index? i >= 0:i=== 0)&&this.lineCardBox(it,index)}
                        {cardArr[1].type === index&&(currentNum === index? i >= 0:i=== 0)&&this.pointCardBox(it,index)}
                        {cardArr[2].type === index&&(currentNum === index? i >= 0:i=== 0)&&this.freeCardBox(it,index)}
                      </li>
                    )
                  }
                  { CouponData[cardArr[index].key].length === 0 &&
                    <li key={cardArr[index].key+'-1'} className={classnames('list_item','noData', cardArr[index].class)}>
                      <div className={classnames('item_box')}>
                        <span>{`暂无${cardArr[index].title}`}</span>
                      </div>
                    </li>
                  }
                </ul>
                { CouponData[cardArr[index].key].length > 1 &&
                  <div className='list_all'>
                    <button className='all_btn' 
                      onClick={()=> {
                        this.setState({
                          currentNum: currentNum === index ? -1:  index
                        })
                      }}
                    >{currentNum === index?'收起':'查看全部'}</button>
                  </div>  
                }
              </div>
            )
          }
        </div>
        {isHandle && <Modal
          title={'卡号绑定'}
          placeholder= {'请输入您的卡号'}
          maxLength = {8}
          cancel={'取消'}
          confirm={'确定'}
          cancelBtn={(e)=>{this.modalBtn('isHandle', false, e)}}
          confirmBtn={(e)=>{this.modalBtn('isHandle', true, e)}}
        />}
        {isHint && <Modal
          type={'text'}
          list={[{ word:'确定要解绑实体卡吗？'}]}
          cancel={'取消'}
          confirm={'确定'}
          cancelBtn={(e)=>{this.modalBtn('isHint', false, e)}}
          confirmBtn={(e)=>{this.modalBtn('isHint', true, e)}}
        />}
        {isKnow && <Modal
          type={'text'}
          title={ explainTitle }
          list={ explainArray }
          confirm={'知道了'}
          confirmBtn={(e)=>{this.modalBtn('isKnow', true, e)}}
        />}

      </div>
    )
  }
}

export default myCoupon
