import classNames from 'classnames'
import React, { Component } from 'react'
import { Toast } from '@/components';
import { setSession, getSession, removeSession } from '@/utils/commons';
import './goodsOrderPay.scss'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { saveConsigneeInfo } from '@/store/actions';
import { isEnv } from "@/config/index";
import { urlResolver, getUrlConcat } from '@/utils/commons';
import { checkisEnv } from '@/utils/utils'
import qs from 'qs'
import { getGoodsDeliveryAddress, queryGoodsDetails } from '@/api/api';

const mapStateToProps =(state)=> {
  return {
    consigneeInfo: state.consigneeInfo
  };
}
const mapDispatchToProps=(dispatch)=> {
  return {
    saveConsigneeInfo: (info) => dispatch(saveConsigneeInfo(info))
  };
}

class GoodsOrderPay extends Component {
  static propTypes = {
    consigneeInfo: PropTypes.object.isRequired,
    saveConsigneeInfo: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      disabledPay: false,
      tabList: [{title: "自提", icon: "oneself" }, {title: "外送", icon: "delivery" }],
      tabLists:{title: "收货地址", icon: "delivery" },
      currentTab: 0,
      shopAddress: '',
      shopName: '',
      goods: [],
      totalMoney: 0,
      totalCount: 0,
      remark: '',
      popupData:[],
      popupBool:false,
      arr:[],
      checked:'',
      isActive:'',
      arrName:[],
    }
  }
  
  componentDidMount() {
    let data = null
    if(!isEnv){
      // let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6IjE4NzkwODA3MTgwIiwidXNlck1vYmlsZSI6IjE4NzkwODA3MTgwIiwidXNlcklkIjozOTk3MTQxLCJvcGVuSWQiOiJvcW1nRjVsM0Ntd2syTE1KZm5qYmVzMGxTOHc0In0.mCmgaef7O1vGy1_RHICLLYTCRoy5OhM5dUICjOyW210";
      let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6InljMzYyNDEwNTYiLCJ1c2VyTW9iaWxlIjoiMTgyNTgxNzU4NDAiLCJ1c2VySWQiOjE1ODY4NDR9.0kE4swArLn29P40K_xzyL5bfmtv_CjcalY1T2s579-I";
      urlResolver(`?token=${token}`)
    } else {
      let url = this.props.location.search;
      const res = urlResolver(url);
      // console.log("32313123232", url)
      const params = res.arr ? JSON.parse(res.arr) : {};
      data =  params.obj || null;
      this.setState({
        tissue:data.tissue
      })
    }
    this.getDeliveryAddress()
    if (!data) {
      return
    }
    if(data.deliveryMethod == 10) {
      this.setState({
        tabList:[{title: "自提", icon: "oneself" }, {title: "外送", icon: "delivery" }]
      })
    }else if(data.deliveryMethod == 0){
      this.setState({
        tabList:[{title: "自提", icon: "oneself" }]
      })
    }else if(data.deliveryMethod == 2){
      this.setState({
        tabList:[{title: "外送", icon: "delivery" }]
      })
    }
    const goodsOrder = getSession('goods-order');
    if (goodsOrder) {
      removeSession('goods-order');
      const info = JSON.parse(goodsOrder);
      this.updateData(info.goods)
      this.setState({
        currentTab: info.currentTab,
        goods: info.goods,
        shopAddress: data.address,
        shopName: data.shopName,
        isActive: data.isActive,
        setActive: data.setActive,
        skuId:data.skuId
      })
      console.log(data.setActive,'setActive')
    } else {
      const marketGoods = (data.marketGoods || []).map((item) => {
        return { count: 1, ...item }
      });
      this.updateData(marketGoods);
      this.setState({
        goods: marketGoods,
        shopAddress: data.address,
        shopName: data.shopName,
        isActive: data.isActive,
        arrName: data.setActive,
        skuId:data.skuId
      })
    }
    this.confirm(false,data)
    console.log(data,'-------------------data')
  }

  getDeliveryAddress() {
    getGoodsDeliveryAddress().then(({ success, data }) => {
      if (success && data) {
        this.deliveryId = data.id;
        const info = {
          contacts: data.name || "", 
          phoneNum: data.phone || "", 
          gender: data.gender  || 0, // 性别：0-未知，1-男，2-女
          address: data.address || "", 
          houseNum: data.roomNumber || "",
          isDefault: data.isDefault || 1 // 是否默认：0-否，1-是
        }
        this.props.saveConsigneeInfo(info)
      }
    })
  }

  addConsigneeInfo(type) {
    const { goods, currentTab } = this.state
    setSession('goods-order', {currentTab: currentTab, goods: goods});
    this.props.history.push({
      pathname: 'consigneeInfo', 
      query: {
        type: type, // 1自提，2外送 
      }
    })
  }

  updateData(list) {
    let money = list.reduce(function (total, value) {
      // return total + (value.currentPrice / 100) ;
      return total + (value.currentPrice ? value.currentPrice/100 : value.marketGoodsSkus[0].pricr/100) * value.count
    }, 0)
    const count = list.reduce(function (total, value) {
      return total + value.count;
    }, 0)
    this.setState({
      totalMoney: money,
      totalCount: count,
      disabledPay: money <= 0
    })
  }
  goPayment() {
    const {currentTab, goods, remark, totalMoney} = this.state
    const { consigneeInfo: {contacts, phoneNum, address, houseNum} } = this.props;
    if (currentTab === 0 && !(this.deliveryId && contacts && phoneNum)) {
      Toast.info("请填写提货人信息")
      return
    }
    if (currentTab === 1 && !(this.deliveryId && contacts && phoneNum && address && houseNum)) {
      Toast.info("请填写收货地址")
      return
    }

    // this.deliveryId = 1
    const params = {
      deliveryType: currentTab + 1, // 配送方式：1-自提，2-外送
      deliveryId: this.deliveryId,
      selfLift: '',
      remark: remark,
      sourceType: 'goods',
      money:totalMoney
    }
    console.log('====',goods)
    const goodsList = goods.map((item,i) => {
      return {goodsId: item.id, quantity: item.count,skuId:item.skuId?item.skuId:item.marketGoodsSkus[i].id}
    });
    console.log(goodsList,'goods')
    params.goodsList = JSON.stringify(goodsList)
    checkisEnv().then(res => {
      switch (res) {
        case 'alipay':
        case 'alipay_mini':
          window.my.navigateTo({
            url: `/pages/moneyPay/index?${qs.stringify(params)}`
          })
          break
        case 'weapp_mini':
          window.wx.miniProgram.navigateTo({
            url: `/pages/moneyPay/index?${qs.stringify(params)}`
          })
          break
      }
    })
  }

  goodsCountReduce(index) {
    const { goods, totalCount } = this.state
    if (totalCount === 1) {
      Toast.info("商品数量不得小于一个噢～")
      return;
    }
 
    const list = goods.map((item, idx) => {
      return (idx === index && item.count > 0 && totalCount > 1) ? {...item, count: item.count - 1} : item
    });
    this.setState({
      goods: list.filter((item) => item.count > 0),
    })
    this.updateData(list)
  }
  goodsCountAdd(index) {
    const { goods } = this.state
    const list = goods.map((item, idx) => {
      return idx === index ? {...item, count: item.count + 1} : item
    });
    this.setState({
      goods: list,
    })
    this.updateData(list)
  }
  popup(item,index,) {
    return 
    let goods = this.state.goods
    let checked = index
    console.log(checked)
    if(goods.length < 1)
    return;
    queryGoodsDetails({id:item.id}).then(({ success, data }) => {
      if (success && data) {
        this.setState({
          popupData:data,
          popupBool:true,
          checked:checked,
          len:JSON.parse(data.skuAttributeList).length
        })
      }
    })
  }
  confirm(bool) {
    let { popupData, arr, arrName, checked, goods } = this.state
    let marketGoodsSkus = popupData.marketGoodsSkus 
    let pricr 
    let skuId
    console.log(marketGoodsSkus,arrName)
    if(!(marketGoodsSkus && marketGoodsSkus.length > 0)) {
      return ;
    }
    
    for (let i = 0; i < marketGoodsSkus.length; i++) {
      for (let index = 0; index < arr.length; index++) {
        
        if(Object.values(JSON.parse(marketGoodsSkus[i].specsList))[index] == arrName[index][1]){
          if(index == arr.length-1) {
            pricr = marketGoodsSkus[i].pricr
            skuId = marketGoodsSkus[i].id
          }
        }else {
          index = arr.length
        }
      }
    }
    console.log(pricr,'-----******************')
    goods[checked].currentPrice = pricr//更改选中商品价格
    goods[checked].skuId = skuId//更改选中商品id
    this.setState({
      goods:goods,
      pricr:pricr,
      
    })
    if(bool) {
      this.setState({
        popupBool:false
      })
    }
    this.updateData(goods)
  }
  setItme(i,value,v,index) {
    // console.log(v,value,i,index)
    let arr = this.state.arr
    let arrName = this.state.arrName ? this.state.arrName : []
    let data = [i,value]
    let dataName = [i,index]
    arrName[i] = dataName
    arr[i] = data
    this.setState({
      arr:arr,
      arrName:arrName
    })
    // JSON.parse(this.state.popupData.skuAttributeList).length
    if(arrName.length == this.state.len) {
      this.confirm(false)
    }
  }
  render() {
    const { consigneeInfo: {contacts, phoneNum, address, houseNum} } = this.props;
    const { pricr, arrName,isActive, disabledPay, checked, popupBool, popupData, currentTab, tabList, shopAddress, shopName, goods, totalMoney, totalCount, tissue, tabLists } = this.state;
    let url = this.props.location.search;
    let data
      const res = urlResolver(url);
      // console.log("32313123232", url)
      const params = res.arr ? JSON.parse(res.arr) : {};
      data =  params.obj || null;
    return (
      <div className="goods-order-pay">
        {
          popupBool && <div className='popup'>
            <div className="popup-banner" onClick={(e) => {
            this.setState({
              popupBool:false
            })
          }}></div>
            <div className="popup-box">
              <div className="popup-head">
                <img src={popupData.goodsUrl.split(',')[0]} alt="" className='popup-goods-img' />
                <div className='popup-price'>
                  <span className='popup-price-title'>￥</span>
                  {
                    pricr ? (pricr/100).toFixed(2) : popupData.currentPrice ? (popupData.currentPrice / 100).toFixed(2) : popupData.marketGoodsSkus && (popupData.marketGoodsSkus[0].pricr / 100).toFixed(2)
                  }
                </div>
                {/* <img src="../../assets/img/close.png" alt="" /> */}
                <img src={require('../../assets/img/close.png')} alt="" className='close' onClick={() => {
                  this.setState({
                    popupBool:false
                  })
                }} />
              </div>
              <div className="popup-info">
                {
                  popupData.skuAttributeList && (Object.entries(JSON.parse(popupData.skuAttributeList))).map((i,v) => {
                    return <div className='popup-module' key={"shop-item" + v}>
                      <div className='module-title'>{Object.keys(JSON.parse(popupData.skuAttributeList))[v]}分类</div>
                      {
                        Object.values(JSON.parse(popupData.skuAttributeList))[v] && Object.values(JSON.parse(popupData.skuAttributeList))[v].map((index,value) => {
                          return <div 
                                  className={classNames('module-type',this.state.arr && this.state.arr[v] && this.state.arr[v][0] == v && this.state.arr[v][1] == value && 'type-red' )}  
                                  key={'title' + index} onClick={() => this.setItme(v,value,i,index)}>
                                  {index}
                                  {/* {
                                    this.state.arr && this.state.arr[v] && console.log(v,'=',this.state.arr[v][0],value)
                                    
                                  } */}
                                </div>
                        })
                      }
                    </div>
                  })
                }
              </div>
            </div>
            <div className="popup-bottom">
              <div className="popup-btn" onClick={() => this.confirm(true)}>确认</div>
            </div>
          </div>
        }
        <div className="goods-top">
          <div className="distribution-info">
            <div className="distribution-tab" >
              { !tissue &&tabList.map((item, index) => 
                <div className={classNames("tab-item", {'active': currentTab === index})} key={'tab-item' + index} onClick={() => {
                  this.setState({
                    currentTab: index
                  })
                }}>
                  <img className="tab-item-img" src={require(`../../assets/img/goods-${item.icon}-${currentTab === index ? 'active' : 'inactive'}.png`)} alt="" />
                  <span>{item.title}</span>
                </div>
              )}
              {
                tissue && <div className='adshop'>
                  <span>{tabLists.title}</span>
                  <img className="tab-item-img" src={require(`../../assets/img/goods-${tabLists.icon}-active.png`)} alt="" />
                </div>
              }
            </div>
            { currentTab === 0 && !tissue && <div className="oneself-box">
                <div className={classNames("consignee-info", "border")} onClick={() => this.addConsigneeInfo(1)}>
                  {
                    contacts && phoneNum ? <div className="consignee-info-title">
                      <div className={classNames("consignee-info-text", "name")}>{contacts}</div>
                      <div className="consignee-info-text">{phoneNum}</div>
                    </div>
                    : <span className="consignee-info-title">请填写提货人信息</span>
                  }
                  <span>编辑</span>
                  <img className="arrow-right" src={require("../../assets/img/arrow-right.png")} alt="" />
                </div>
                <div className="oneself-info-title">自提点</div>
                <div className="address-detail">{shopAddress}</div>
              </div> 
            }
            { (currentTab === 1 || tissue )&& <div className="delivery-box">
                <div className={classNames("consignee-info", "border")} onClick={() => this.addConsigneeInfo(2)}>
                  {
                    contacts && phoneNum && address ? <div className="consignee-info-title">
                      <div className={classNames("consignee-info-text", "name")}>{contacts}</div>
                      <div className="consignee-info-text">{phoneNum}</div>
                    </div> 
                    : <div className="consignee-info-title">
                        <img className="address-icon" src={require("../../assets/img/address-icon.png")} alt="" />
                        <span className="shipping-address-title">请填写收货地址</span>
                      </div>
                  }
                  { contacts && phoneNum && <span>编辑</span> }
                  <img className="arrow-right" src={require("../../assets/img/arrow-right.png")} alt="" />
                </div>
                <div className={classNames("address-detail", "delivery-address")}>{address + houseNum}</div>
              </div>
            }
          </div>
          <div className="goods-info">
            <div className="goods-list">
              <div className="goods-list-header">
                <span className="shop-name">{!tissue && shopName}</span>
                <span>共{totalCount}件</span>
              </div>
              { goods.map((item, index) => 
                <div className="goods-item" key={"shop-item" + index}>
                  <img className="goods-img" src={item.goodsUrl.split(",")[0]} alt="" />
                  <div className="goods-text">
                    <div className="goods-name">{item.goodsName}</div>
                    <div className="goods-sort" onClick={() => this.popup(item,index)}>
                      {/* <span className='sort-box'>分类可选择</span> */}
                      {
                        (goods.length != 1 ? checked == index : true) && arrName && arrName.map((i,v) => {
                          return <span className='sort-box' key={"shop-item" + v}>{i[1]}</span>
                        })
                      }
                      {
                        !arrName && data.isActive && data.isActive.map((i,v) => {
                          return <span className='sort-box' key={"shop-item" + i}>{i}</span>
                        })
                      }
                      
                      {/* <div className="sort-img">
                        <img src={require('../../assets/img/right.png')} alt="" />
                      </div> */}
                      {/* {
                        !arrName && !data.isActive && item.skuAttributeList && (Object.keys(JSON.parse(item.skuAttributeList))).map((i,v) => {
                          return <span className='sort-box' key={"shop-item" + i}>{i}</span>
                        })
                      } */}
                    </div>
                    <div className="goods-price">¥
                    {
                      item.currentPrice ? (item.currentPrice/100).toFixed(2) : item.marketGoodsSkus && (item.marketGoodsSkus[0].pricr/100).toFixed(2)
                    }</div>
                  </div>
                  <div className="goods-count">
                    <div className="count-action" onClick={this.goodsCountReduce.bind(this, index)}>
                      <div className={classNames("action-content", "reduce")}>-</div>
                    </div>
                    <span>{item.count}</span>
                    <div className="count-action" onClick={this.goodsCountAdd.bind(this, index)}>
                      <div className={classNames("action-content", "add")}>+</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="order-info">
              <div className="item">
                <span className="item-title">商品总额</span>
                <span className={classNames("item-value", "price")}>¥{totalMoney.toFixed(2)}</span>
              </div>
              <div className="item">
                <span className="item-title">优惠券</span>
                <span className="item-value">暂无</span>
              </div>
              {
                currentTab === 1 && <div className="item">
                  <span className="item-title">配送费</span>
                  <span className="item-value">暂无</span>
                </div>
              }
              <div className="item">
                <span className="item-title">订单备注</span>
                <input className="order-remarks" placeholder="填写备注信息" onChange={(e) => {
                  this.setState({
                    remark: e.currentTarget.value
                  })
                }}></input>
              </div>
            </div>
          </div>
        </div>
        <div className="goods-bottom">
          <div className="pay-info">
            <span className="pay-money-label">待支付:</span>
            <span className="pay-money">{totalMoney.toFixed(2)}</span>
            <span className="pay-money-label">元</span>
          </div>
          <button className={classNames("pay-button", {'disabled': disabledPay})} 
            disabled={disabledPay}
            onClick={() => this.goPayment()}>去支付</button>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoodsOrderPay);   