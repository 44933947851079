import React, { Component } from 'react';
import { connect } from 'react-redux';
import './tradinglist.scss';
import ItemList from './itemList/itemList'
import InfiniteScroll from 'react-infinite-scroller'
import { DefaultPageLogo } from '@/components';
import { tradingRecord, offlineCardOrderList,myBalanceRefundList } from '@/api/api';
import { isEnv } from "@/config/index";
import { urlResolver, getUrlConcat } from '@/utils/commons';//setSession
import store from '@/store';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { saveUserInfo } from '@/store/actions';
import { checkisEnv } from '@/utils/utils'
import qs from 'qs'

const mapStateToProps =(state)=> {
  return {
    userInfo: state.userInfo
  };
}

const mapDispatchToProps=(dispatch)=> {
  return {
    saveUserInfo: (userInfo) => dispatch(saveUserInfo(userInfo))
  };
}

class tradinglist extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    saveUserInfo: PropTypes.func.isRequired,
  }
  state = {
    List:[],
    page: 1,//页数
    itemcode:1,
    refundPage:1,
    total: 0,
    hasMore: true, // 是否开启下拉加载
    index:1,
    ...store.getState()
  }
  componentDidMount(){
    let index = this.state.index
    let url = this.props.location.search;
    if(!isEnv){
      let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6InljMzYyNDEwNTYiLCJ1c2VyTW9iaWxlIjoiMTgyNTgxNzU4NDAiLCJ1c2VySWQiOjE1ODY4NDR9.0kE4swArLn29P40K_xzyL5bfmtv_CjcalY1T2s579-I";
      urlResolver(`?token=${token}`)
    } else {
      urlResolver(url);
    }
    console.log(urlResolver(url),'url------------------*********************-')
    if(urlResolver(url).index) {
      this.setState({
        index:this.props.location.state?1:urlResolver(url).index
      })
    }
    if(this.props.location.state?index:urlResolver(url).index == 1) {
      this.getList(1)
    }else if(this.props.location.state?index:urlResolver(url).index == 2) {
      this.initialize(1)
    }else if(this.props.location.state?index:urlResolver(url).index == 3){
      this.refundList(1)
    }else {
      this.getList(1)
    }
  }
  initialize(itemcode = 1) {
    offlineCardOrderList(itemcode).then(({ success, data })=>{
      if(success){
        let { List } = this.state;
        this.setState({
          List: [...List,...data.records],
          total: data.total,
          itemcode: itemcode+1,
        })
      }
    })
  }
  // 获取充电记录
  getList(page1 = 1){
    tradingRecord(page1).then(({ success, data })=>{
      if(success){
        const { List, page } = this.state;
        this.setState({
          List: [...List,...data.list],
          total: data.total,
          page: page +1,
        })
      }
    })
  }
  //退款交易记录
  refundList(page1 = 1){
    myBalanceRefundList(page1).then(({ success, data })=>{
      if(success){
        const { List, refundPage } = this.state;

        this.setState({
          List: [...List,...data.records],
          total: data.total,
          refundPage: page1 +1,
        })
      }
    })
  }
  nextPageFun =()=>{
    const { page, itemcode, total, List, index, refundPage } = this.state;
    console.log(index)
    if(index == 1) {
      if (total>=0 && List.length >= total) {
        return false
      }
      this.getList(page);
    }else if(index ==2) {
      if (total>=0 && List.length >= total) {
        return false
      }
      this.initialize(itemcode);
    } else {
      if (total>=0 && List.length >= total) {
        return false
      }
      this.refundList(refundPage)
    }
    
  }
  // 跳转交易记录详情
  itemInfo(item){
    let { index } = this.state
    if(index == 1) {
      this.props.history.push(`/tradingInfo${getUrlConcat(item)}`)
    }else if(index == 2) {
      this.props.history.push(`/machineTradingInfo${getUrlConcat(item)}`)
    }else {
      console.log(item.combinationCode)
      checkisEnv().then(res => {
        switch (res) {
          case 'alipay':
          case 'alipay_mini':
            window.my.navigateTo({
              url: `/pages/mine/pages/refund-details/refund-details?combinationCode=${item.combinationCode}`
            })
            break
          case 'weapp_mini':
            window.wx.miniProgram.navigateTo({
              url: `/pages/mine/pages/refund-details/refund-details?combinationCode=${item.combinationCode}`
            })
            break
        }
      })
    }
    
  }
  setIndex(index) {
    if(index == 1) {
      this.setState({
        index:index,
        itemcode:1,
        List:[]
      })
      this.getList(1)
    }else if(index == 2 ){
      this.setState({
        index:index,
        page:1,
        List:[]
      })
      this.initialize()
    } else {
      this.setState({
        index:index,
        refundPage:1,
        List:[]
      })
      this.refundList()
    }
  }
  render() {
    const { List, hasMore,index } = this.state;
    return (
      <div className='list_box'>
        <div className="list_head">
          <div className={classNames('head_name',index == 1 ?'head_names':'head_name1')} onClick={() => this.setIndex(1)}>充电交易</div>
          <div className={classNames('head_name',index == 2 ?'head_names':'head_name1')} onClick={() => this.setIndex(2)}>充值交易</div>
          <div className={classNames('head_name',index == 3 ?'head_names':'head_name1')} onClick={() => this.setIndex(3)}>退款交易</div>
        </div>
        <div className="listLine">
          <InfiniteScroll
            initialLoad={false} // 不让它进入直接加载
            pageStart={1} // 设置初始化请求的页数
            loadMore={this.nextPageFun}  // 监听数据请求
            hasMore={hasMore} // 是否继续监听滚动事件 true 监听 | false 不再监听
            useWindow={false} // 不监听 window 滚动条
          >
            { List.length !== 0
              ? List.length !== 0 && List.map((item,i)=>
                  <ItemList index={index} itemData={item} key={'id'+i} itemFun={this.itemInfo.bind(this,item)} />
                )
              : <DefaultPageLogo />  
            }
          </InfiniteScroll>
        </div>
       
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(tradinglist);