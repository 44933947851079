import React, { Component } from 'react'
import { Toast } from '@/components';
// import axios from 'axios'
import './goodsConsigneeInfo.scss'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import { saveConsigneeInfo } from '@/store/actions';
import { saveGoodsDeliveryAddress } from '@/api/api';

class GoodsConsigneeInfo extends Component {
  constructor(props) {
    super(props)

    const { contacts, phoneNum, gender, address, houseNum } = props.consigneeInfo
    this.state = {
      type: 1,
      contacts,
      phoneNum,
      gender:1,
      address,
      houseNum, 
    }
  }
  
  componentDidMount() {
    // type: 1自提，2外送
    this.setState({
      type: this.props.location.query && this.props.location.query.type || 1
    })
  }

  submitInfo() {
    const {type, contacts, phoneNum, gender, address, houseNum} = this.state
    if (!contacts) {
      Toast.info("请输入联系人姓名")
      return
    }
    if (!phoneNum) {
      Toast.info("请输入手机号")
      return
    }
    if (type === 2) {
      if (!address) {
        Toast.info("请填写您所在的小区名称")
        return
      }
      if (!houseNum) {
        Toast.info("请输入门牌号")
        return
      }
    }
    const params = { name: contacts, phone: phoneNum, isDefault: 1 }
    if (type === 2) {
      params.gender = gender;
      params.address = address;
      params.roomNumber = houseNum;
    }
    saveGoodsDeliveryAddress(params).then(({success, errormsg}) => {
      if (success) {
        Toast.success("保存成功")
        this.props.history.go(-1)
      } else {
        Toast.info(errormsg)
      }
    })
    
  }
  // searchAroundPlace = () => {
  //   axios.get('https://restapi.amap.com/v5/place/around', {
  //     params: {
  //       key: '04467cee23bea3928c16cd2696655965',
  //       location: '120.015781,30.289353',
  //       keywords: '',
  //       show_fields: 'children',
  //       types:'120000|120201|120302|120200|120300|120303',
  //       radius: '500',
  //       sortrule: 'weight',
  //       page_size: 20,
  //       page_num: 1,
  //     }
  //   }).then((res) => {
  //     console.log("searchAroundPlace", res)
  //   })
  // }

  render() {
    const {type, contacts, phoneNum, gender, address, houseNum} = this.state
    return (
      <div>
        <form className="goods-consignee-info" name="myForm" onSubmit={(e) => {
          e.preventDefault();
          this.submitInfo()
        }}>
          <div className="consignee-info-top">
            <div className="input-item">
              <div className="item-title">联系人</div>
              <input className="input" value={contacts} type="text" placeholder="请输入联系人姓名" onChange={(e) => {
                this.setState({
                  contacts: e.currentTarget.value
                })
              }}/>
            </div>
            <div className="input-item">
              <div className="item-title">手机号</div>
              <input className="input" value={phoneNum} type="text" maxLength="11" placeholder="请输入手机号" onChange={(e) => {
                this.setState({
                  phoneNum: e.currentTarget.value
                })
              }}/>
            </div>
            { type === 2 && <div>
                <div className="gender-item">
                  <div className="item-title">性别</div>
                  <div className="gender-radio-group">
                    <div className="gender-radio">
                      <input className="radio" type="radio" checked={gender === 1} id="male" onChange={(e) => {
                        this.setState({
                          gender: 1
                        })
                      }}/>
                      <label className="radio-label" htmlFor="male">男</label>
                    </div>
                    <div className="gender-radio">
                      <input className="radio" type="radio" checked={gender === 2} id="female" onChange={(e) => {
                        this.setState({
                          gender: 2
                        })
                      }}/>
                      <label className="radio-label" htmlFor="female">女</label>
                    </div>
                  </div>
                </div>
                <div className="input-item">
                  <div className="item-title">{false?'小区222名称':'收货地址'}</div>
                  <input className="input" value={address} type="text" placeholder={false?'请填写您所在的小区名称':'如：xx省xx市xx区xx街道xx小区名'} onChange={(e) => {
                    this.setState({
                      address: e.currentTarget.value
                    })
                  }}/>
                </div>
                <div className="input-item">
                  <div className="item-title">门牌号</div>
                  <input className="input" value={houseNum} type="text" placeholder="如：128幢1单元204室" onChange={(e) => {
                    this.setState({
                      houseNum: e.currentTarget.value
                    })
                  }}/>
                </div>
              </div>
            }
          </div>
          <div className="consignee-info-bottom">
            <button className="button-confirm" type="submit">确认</button>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps =(state)=> {
  return {
    consigneeInfo: state.consigneeInfo
  };
}
const mapDispatchToProps=(dispatch)=> {
  return {
    saveConsigneeInfo: (info) => dispatch(saveConsigneeInfo(info))
  };
}

GoodsConsigneeInfo.propTypes = {
  consigneeInfo: PropTypes.object.isRequired,
  // saveConsigneeInfo: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  // mapDispatchToProps
)(GoodsConsigneeInfo);