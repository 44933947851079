import React, { Component } from 'react';
import { getPlatformUserActivity } from '@/api/api';
import { isEnv } from "@/config";
import { urlResolver } from '@/utils/commons';
import { checkisEnv } from '@/utils/utils'
import './dragonBoatFestival.scss';
import classNames from 'classnames';
import qs from 'qs'
export class payActive extends Component {
  state = {
    arrList:[],
    textarr:[{
        text_cont: '包含5元*2张、3元*1张、2元18张，1元17张',
        icon:'88',
        type:'电动车用户专享'
      },
      {
        text_cont: '包含5元*2张、3元*2张、2元*10张、1元*8张',
        icon:'9',
        type:'电动车用户专享'
      },
      {
        text_cont: '包含3元*1张、2元*10张、1元*10张',
        icon:'9',
        type:'电动车用户专享'
      },
      {
        text_cont: '包含2元*4张、1元*1张',
        icon:'91',
        type:'电动车用户专享'
      },
      {
        text_cont: '包含10元*6张、20元*3张',
        icon:'9',
        type:'汽车用户专享'
      },
    ]
  }
  componentDidMount() {
    if (!isEnv) {
      let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6IjE1ODM2NDAyMDI0IiwidXNlck1vYmlsZSI6IjE1ODM2NDAyMDI0IiwidXNlcklkIjozMjYxNDgwLCJvcGVuSWQiOiJvcW1nRjVyUmg4bktJV0hRR3ZycTdhOXpEbkl3In0.Y-ksQrIwkYSet_aeLpXlqcqQu0oPCjhWz4ETE4lVOMM"
      urlResolver(`?token=${token}`)
    } else {
      let url = this.props.location.search;
      urlResolver(url);
    }
    this.getList();
  }
  // 获取我的优惠列表所有数据
  getList() {

    getPlatformUserActivity({}).then(({ success, data }) => {
      if (success) {
        if (data && Array.isArray(data)) {
          const {textarr} = this.state
          for(let i = 0; i < data.length; i ++) {
            data[i].consumeMinMoney = Number(data[i].consumeMinMoney) / 100
            data[i].money = Number(data[i].money) / 100
            data[i].text_cont = textarr[i].text_cont
            data[i].icon = textarr[i].icon
            data[i].type = textarr[i].type
          }
          this.setState({
            arrList:data
          })
        }
      }
    })
  }
  goPay = (item) => {
    // console.log('item====',item)
    const params = {
      money: item.consumeMinMoney.toString(),
      activityId: item.couponId,
      sourceType: 'active',
    }
    checkisEnv().then(res => {
      switch (res) {
        case 'alipay':
        case 'alipay_mini':
          window.my.navigateTo({
            url: `/pages/moneyPay/index?${qs.stringify(params)}`
          })
          break
        case 'weapp_mini':
          window.wx.miniProgram.navigateTo({
            url: `/pages/moneyPay/index?${qs.stringify(params)}`
          })
          break
      }
    })
  }
  render() {
    const { arrList} = this.state
    return (
      <div className='newyear_active'>
        <div className='active_box'>
          <div className='active_cont'>
            {/* <div className="active_date">
              <h3>2021年6月15号-2021年7月31号</h3>
            </div> */}
            <ul className='money_list'>
              {arrList&& arrList.map((item, index) =>
                <li className='moneylist_item' key={'id' + index}  onClick={
                  () => {
                    this.goPay(item)
                  }
                }>
                  <div className={classNames('left_box',item.icon==9?'left_box9':item.icon==88 ?'left_box88':'left_box91')}>
                    <span className='tags'>￥</span>
                    <span className='money'>{item.money}</span>
                    <div className="type">
                      {item.type}
                    </div>
                  </div>
                  <div className='right_box'>
                    <div className='left_cont'>
                      <div className='top_cont'>
                        <div className='title'>充值{item.consumeMinMoney}元就送</div>
                        <div className='sign'>
                          <img src={require('../../assets/img/sign1.png')} alt=""/>
                        </div>
                      </div>
                      <div className='bot_cont'>{item.text_cont}</div>
                    </div>
                    <div className='right_cont'>
                      <button>去充值</button>
                    </div>
                  </div>
                </li>

              )}
            </ul>
          </div>
          <div className='active_bot'></div>
        </div>
      </div>
    )
  }
}

export default payActive
