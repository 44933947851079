
import React, { Component } from 'react'
import './insureExplanation.scss';
export default class InsureExplanation extends Component {
  render() {
    return (
      <div className="low_carbon_detail">
        <img
          src={require('../../assets/img/insure_explanation4.png')}
          alt="暂无图片"
          className="low_carbon_detail_img"
        />
      </div>
    )
  }
}