import React, { Component } from 'react'
import './lowCarbonDetail.scss';
export default class lowCarbonDetailzfb extends Component {
  render() {
    return (
      <div className="low_carbon_detail">
        <img
          src={require('../../assets/img/low_carbon_detail_zfb.png')}
          alt="暂无图片"
          className="low_carbon_detail_img"
        />
      </div>
    )
  }
}