import React, { Component } from 'react'
import './accountBalance.scss';
import { isEnv } from "@/config/index";
import { urlResolver } from '@/utils/commons';

export class accountBalance extends Component {
  state = {
    urlData: {
      money:0,
    }
  }
  componentDidMount(){
    if( !isEnv ) {
      this.setState({
        urlData: urlResolver(this.props.location.search)
      })
    }
  }
  // 余额充值
  balancePay = () =>{
    const { urlData:{ money }} = this.state;
    const param = {
      money: money,
    }
    this.props.history.push(`/balanceCharge?params=${JSON.stringify(param)}`)
  }
  render() {
    const { urlData:{ money} } = this.state;
    return (
      <div className='accountBalance_container'>
        <div className='accountBalance_box'>
          <div className='accountBalance_Balance'>
            <h6 className='Balance_Num'>{money}</h6>
            <h6 className='Balance_text'>账户余额(元)</h6>
          </div>
          <button onClick={()=>{this.balancePay()}} type="submit" className='accountBalance_btn' >快速充值</button>
        </div>
      </div>
    )
  }
}

export default accountBalance
