import React, { Component } from 'react';
import { connect } from 'react-redux';
import './consumption.scss';
import { DefaultPage } from '@/components';
import { queryConsumeFundRecords } from '@/api/api';
import { isEnv } from "@/config/index";
import classnames from 'classnames';
import { urlResolver, stateModelSum, } from '@/utils/commons';//setSession
import store from '@/store';
import PropTypes from 'prop-types';
import { saveUserInfo } from '@/store/actions';

const mapStateToProps =(state)=> {
  return {
    userInfo: state.userInfo
  };
}

const mapDispatchToProps=(dispatch)=> {
  return {
    saveUserInfo: (userInfo) => dispatch(saveUserInfo(userInfo))
  };
}

class consumption extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    saveUserInfo: PropTypes.func.isRequired,
  }
  state = {
    List:[],
    page: 1,//页数
    total: 0,
    ...store.getState()
  }
  componentDidMount(){
    console.log('交易记录')
    if(!isEnv){
      let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6IjE4NzkwODA3MTgwIiwidXNlck1vYmlsZSI6IjE4NzkwODA3MTgwIiwidXNlcklkIjozOTk3MTQxLCJvcGVuSWQiOiJvcW1nRjVsM0Ntd2syTE1KZm5qYmVzMGxTOHc0In0.mCmgaef7O1vGy1_RHICLLYTCRoy5OhM5dUICjOyW210";
      urlResolver(`?token=${token}`)
      console.log(urlResolver(`?token=${token}`),'============')
    } 
    // else {
      let url = this.props.location.search;
      urlResolver(url);
      console.log(url,'--------------','交易记录')
    // }
    // let url = this.props.location.search;
    //   urlResolver(url);
    let id = url.split('?')[1].split('&')[0].split('=')[1]
    console.log(id)
    this.getList(id)
  }
  // // 获取充电记录
  getList(page){
    console.log('调用充电记录')
    queryConsumeFundRecords(page).then(({ success, data })=>{
      if(success){
        const { List, page } = this.state;
        console.log(data)
        this.setState({
          List: data
        })
      }
    })
  }
  // nextPageFun =()=>{
  //   const { page,  total, List } = this.state;
  //   if (total>=0 && List.length >= total) {
  //     return false
  //   }
  //   this.getList(page);
  // }
  orderState =(item)=>{
    const {fundActionType, State}  = item
    if(fundActionType === 0 || fundActionType === 2){// true +   false -
      return true;
    } else {
        return false;
    }
  }
  render() {
    let { List, hasMore } = this.state;
    // const { itemData:{Bao, CreateTime, Money, TransactionType, Ways} } = this.props;
    List = List.reverse()
    return (
      <div className='list_box'>
          { List.length !== 0
            ? List.length !== 0 && List.map((item,index)=>
              <div className='itemRecord' onClick={()=>this.props.itemFun()} key={'id'+index}>
                <div className='Record_info'>
                  <h6 className='info_title'>{`${stateModelSum(item.fundActionType )}`}</h6>
                  <span className='info_time'>{item.createTime}</span>
                </div>
                <span className={classnames('Record_money',(this.orderState(item) || item.fundActionType == 4) &&'active')}>{`${this.orderState(item) || item.fundActionType == 4?'+':''}${(item.changeAmount/100).toFixed(2)}`}</span>
                {
                  console.log(item)
                }
              </div>
                // <ItemList itemData={item}   />
              )
            : <DefaultPage />  
          }
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(consumption);