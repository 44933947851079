import React from 'react';
import '@/assets/css/base.scss'
import Routers from './router'
import {Route} from 'react-router-dom';

const RouteWithSubRoutes = route => {
  return (
    <Route
      exact
      path={route.path}
      render={props => {
        document.title = route.title || "";
        return <route.component {...props} routes={route.routes}></route.component>
      }}
    />
  );
};

export default () => {
  return Routers.map((route, i) => {
    return <RouteWithSubRoutes key={'page'+i} {...route}/>
  })
};
