import React, { Component } from 'react';
import { queryChargePower } from '@/api/api';
import * as echarts from 'echarts';
import { isEnv } from "@/config/index";
import { urlResolver, stateModelSum, } from '@/utils/commons';
import './powerGraph.scss';
export default class powerGraph extends Component {
  state = {
    xAxis:[],
    series:[]
  }
  componentDidMount() {
    if (!isEnv) {
      let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6IjE1ODM2NDAyMDI0IiwidXNlck1vYmlsZSI6IjE1ODM2NDAyMDI0IiwidXNlcklkIjozMjYxNDgwLCJvcGVuSWQiOiJvcW1nRjVyUmg4bktJV0hRR3ZycTdhOXpEbkl3In0.Y-ksQrIwkYSet_aeLpXlqcqQu0oPCjhWz4ETE4lVOMM"
      urlResolver(`?token=${token}`)
    } 
    let url = this.props.location.search;
    let arr = urlResolver(url)
    this.getList(arr);
  }
  getList(arr) {
    console.log(arr)
    let query = {
      orderId:arr.orderId,
      deviceNumber:arr.deviceNumber,
      startTime:arr.createTime,
    }
    if(arr.expectEndTime != 0) {
      query.endTime = arr.expectEndTime
    }
    queryChargePower(query)
      .then((res) => {
        if(res.success) {
          const { series, xAxis} = res.data
          this.setState({
            series: series,
            xAxis: xAxis
          })
          if(xAxis.length > 0 && series.length > 0) {
            for(let i = 0;i < xAxis.length;i++){
              xAxis[i] = xAxis[i].slice(11,16)
              if(i === 0) {
                xAxis[i] = '        ' + xAxis[i]
              }else if(i === xAxis.length-1) {
                xAxis[i] = xAxis[i] +'        '
              }
            }
            this.echarts(xAxis,series)
          }
        }
      })
  }
  echarts(xAxisData,seriesData) {
    var myChart = echarts.init(document.getElementById('mian'));
    // // 绘制图表
    myChart.setOption({
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: xAxisData,
        axisLine: {
          show: true//x轴线显隐
        },
        axisTick: {
          show: true//x轴刻度显隐
        },
        axisPointer: {
          show: true//划入标线显隐
        },
      },
      yAxis: {
        name: '单位：功率/W',
        type: 'value',
        splitLine: {
          lineStyle: {
            type: 'dashed'
          }
        },
        axisTick: {
          show: false//x轴刻度显隐
        },
        axisPointer: { //划入是否显示峰值
          show: true,
          type: 'none',
          label: {
            show: false
          }
        },
      },
      grid:{
        top:'44px',//距上边距
        left:'60px',//距离左边距
        right:'30px',//距离右边距
        bottom:'44px',//距离下边距
      },
      color: '#3F88FB',
      tooltip: {
        trigger: 'none',
        axisPointer: {
            type: 'cross'
        }
    },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        position: 'top',
        formatter: function (params) {
          console.log(params)
          var htmlStr = '<div>';
          htmlStr += params[0].value + 'W';
          htmlStr += '</div>';
          return htmlStr;
        },
        
      },
      axisPointer: {
        link: {xAxisIndex: 'all'},
        label: {
            backgroundColor: '#777'
        }
      },
      legend: {
        bottom: "0", //距离顶部位置
        height:'100%',
        textStyle: {
           color: "#4c9bfd", //图例文字颜色
           fontSize: 14, //图例字体大小
           padding: 0, //内边距
        }
      },
      series: [{
        data: seriesData,
        type: 'line',
        symbol: "circle",//必选
        itemStyle: {
          normal: {
            lineStyle: {
              width: 1// 0.1的线条是非常细的了
            }
          }
        },
      }]
    });
  }
  render() { 
    const { xAxis, series} = this.state
    return (
      <div className="powerGraph">
        {
          (xAxis.length === 0 || series.length === 0) ?
          <div className="power_curve">
            <img className="power_img" src="https://changchong.oss-cn-hangzhou.aliyuncs.com/taro/icon/rechargeRecord_noData.png"/>
            暂无数据
          </div>
          :<div id="mian" style={{ width: '100%', height: '300px' }}></div>
        }
        
      </div>
    )
  }
}

