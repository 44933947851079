import React, {Component} from 'react';
import { imgUrl } from '@/config/index';
import { Interim } from '@/components';
import './defaultPage.scss';

class defaultPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInterim: true
    };
  }
  componentDidMount() {
    this.timer = setTimeout(()=>{
      this.setState({
        isInterim: false
      },()=>{
        clearTimeout(this.timer)
      })
    },1000)
  }
  componentWillUnmount () {
    clearTimeout(this.timer)
  }
  render () {
    const { isInterim } = this.state;
    return (
      <div className='container'>
        { !isInterim
          ?<div className='center_box'>
            <img className='center_img' src={imgUrl+'noData/deal.png'} alt="" />
            <span className='center_describe'>{'暂无交易记录'}</span>
          </div>
          :<Interim />
        }
      </div>
    )
  }
}

export default defaultPage

